import React, { useContext, useState, useEffect } from "react";
import { AdContext } from "../context/AdContext";
import LinearProgress from "@mui/material/LinearProgress";
import MainItem from "./MainItem";
import Header from "./Header";
import Footer from "./Footer";
import Charts from "./Charts";
import About from "./About";
import AirportsAndRigs from "./AirportsAndRigs";
import "../GridCss.css";

const Main = () => {
  const contextObj = useContext(AdContext);
  const metar = contextObj.metar[0];
  const showCharts = contextObj.showCharts[0];
  const toggleAbout = contextObj.toggleAbout[0];
  const toggleAirportData = contextObj.toggleAirportData[0];
  const weatherLoading = contextObj.weatherLoading[0];
  const weatherLoadingError = contextObj.weatherLoadingError[0];

  const [isUltrawide, setIsUltrawide] = useState(false);

  useEffect(() => {
    const checkAspectRatio = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = width / height;

      // Check if the aspect ratio is approximately 21:9
      setIsUltrawide(aspectRatio > 2);
      console.log("Aspect ratio: ");
      console.log(aspectRatio);
    };

    // Run on initial load
    checkAspectRatio();

    // Add event listener for window resize
    window.addEventListener("resize", checkAspectRatio);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", checkAspectRatio);
    };
  }, []);

  const gridStyles = {
    grid: {
      display: "grid",
      gridTemplateColumns: isUltrawide ? "1fr 1fr" : "1fr",
      gap: "16px",
      gridAutoFlow: "dense",
      maxWidth: "100%",
    },
  };

  function presentWindow() {
    if (toggleAbout) {
      return <About />;
    } else if (toggleAirportData) {
      return <AirportsAndRigs />;
    } else {
      if (showCharts) {
        return <Charts />;
      }
      return (
        <div style={gridStyles.grid}>
          <MainItem />
        </div>
      );
    }
  }

  if (metar !== undefined) {
    return (
      <div style={styles.main}>
        <Header />
        {weatherLoadingError ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <h4 style={{ color: "#cc172a", margin: 0 }}>
              ERROR LOADING WEATHER
            </h4>
          </div>
        ) : null}
        {weatherLoading ? (
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <LinearProgress color="inherit" />
          </div>
        ) : null}
        <div style={{ padding: 10, paddingTop: 0 }}>{presentWindow()}</div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div style={styles.empty}>
        <div>
          <Header />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <LinearProgress color="inherit" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

const styles = {
  main: {
    width: "100%",
    minHeight: "100vh",
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "100vh",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    gridAutoFlow: "dense",
    maxWidth: "100%",
  },
};

export default Main;
